// eslint-disable-next-line no-undef
const axios = require("../config/axios.config.js").default;
export default {
  namespaced: true,
  state: () => ({
    history: {
      data: [],
      total: 0
    },
    history_trade: {
      data: [],
      total: 0
    },
    price: [],
    coin_address: { QrCode: "", address: "" },
    action_list: [],
    statisticWithdrawSBS: null,
  }),

  getters: {
    History: state => {
      return state.history;
    },
    Price: state => {
      return state.price;
    },
    CoinAddress: state => {
      return state.coin_address;
    },
    ActionList: state => {
      return state.action_list;
    },
    HistoryTrade: state => {
      return state.history_trade;
    },
    StatisticWithdrawSBS: state => {
      return state.statisticWithdrawSBS;
    }
  },

  actions: {
    req_getPrice: async function ({ commit }, input) {
      axios
        .get("wallet/price", {
          params: input
        })
        .then(function (data) {
          if (data) {
            commit("SET_WALLET_HISTORY", data.history);
            commit("SET_PRICE_COIN", data.symbol);
          }
        });
    },
    req_getHistoryWalletTrade: function ({ commit }, input) {
      axios
        .get("wallet/history-wallet-trade", {
          params: input
        })
        .then(function (data) {
          if (data) {
            commit("SET_WALLET_HISTORY_TRADE", data.history);
          }
        });
    },
    req_getAddress: async function ({ commit }, input) {
      axios.post("wallet/address", { coin: input }).then(function (data) {
        if (data) {
          commit("SET_COIN_ADDRESS", data);
        }
      });
    },
    req_getListAction: async function ({ commit }) {
      axios.get("wallet/action").then(function (data) {
        if (data) {
          commit("SET_LIST_ACTION", data);
        }
      });
    },
    req_postWithdraw: async function ({ dispatch, commit }, input) {
      axios.post("wallet/withdraw", input).then(function () {
        commit('WITHDRAW_SUCCESS');
        dispatch("req_getPrice");
      });
    },
    req_postTransfer: async function ({ dispatch, commit }, input) {
      axios.post("wallet/transfer", input).then(function () {
        commit('TRANSFER_SUCCESS');
        dispatch("req_getPrice");
      });
    },
    req_postDepositFromBalanceMainToGame: async function ({ commit }, input) {
      axios.post("wallet/deposit-trade", input).then(function () {
        commit('DEPOSIT_FROM_BALANCE_MAIN_TO_GAME_SUCCESS');
      });
    },
    req_postDepositFromBalanceStakingToGame: async function ({ commit }, input) {
      axios.post("wallet/deposit-stake-trade", input).then(function () {
        commit('DEPOSIT_FROM_BALANCE_STAKE_TO_GAME_SUCCESS');
      });
    },
    req_postDepositFromProfitStakingToGame: async function ({ commit }) {
      axios.post("wallet/deposit-expected-profit-trade").then(function () {
        commit('DEPOSIT_FROM_BALANCE_PROFIT_STAKE_TO_GAME_SUCCESS');
      });
    },
    req_postWithdrawFromGameToMain: async function ({ commit }, input) {
      axios.post("wallet/withdraw-trade", input).then(function () {
        commit('WITHDRAW_FROM_GAME_TO_MAIN_SUCCESS');
      });
    },
    req_postWithdrawFromGameStakeToMain: async function ({ commit }, input) {
      axios.post("wallet/withdraw-stake-trade", input).then(function () {
        commit('WITHDRAW_FROM_GAME_STAKE_TO_MAIN_SUCCESS');
      });
    },
    req_getStatisticWithdrawSBS: async function ({ commit }, input) {
      axios.get("wallet/statictical-stake-game", input).then(function (data) {
        commit('STATISTIC_WITHDRAW_SBS', data);
      });
    },
    req_postRefreshBalanceDemo: async function ({ commit }) {
      axios.post("wallet/deposit-trade-demo").then(function () {
        commit('REFRESH_BALANCE_DEMO_SUCCESS');
      });
    },
  },

  mutations: {
    SET_WALLET_HISTORY: function (state, data) {
      state.history.data = data.data;
      state.history.total = data.last_page;
    },
    SET_WALLET_HISTORY_TRADE: function (state, data) {
      state.history_trade.data = data.data;
      state.history_trade.total = data.last_page;
    },
    SET_PRICE_COIN: function (state, data) {
      state.price = data;
    },
    SET_COIN_ADDRESS: function (state, data) {
      state.coin_address = data;
    },
    SET_LIST_ACTION: function (state, data) {
      state.action_list = data;
    },
    WITHDRAW_SUCCESS() {
      this.dispatch('userinfo/req_getBalance');
      this.dispatch('history/req_getHistory', {
        type: 'exchange',
        page: 1,
      });
    },
    TRANSFER_SUCCESS() {
      this.dispatch('userinfo/req_getBalance');
      this.dispatch('history/req_getHistory', {
        type: 'exchange',
        page: 1,
      });
    },
    DEPOSIT_FROM_BALANCE_MAIN_TO_GAME_SUCCESS() {
      this.dispatch('userinfo/req_getBalance')
      this.dispatch('history/req_getHistory', {
        type: 'exchange',
        page: 1,
      });
    },
    DEPOSIT_FROM_BALANCE_STAKE_TO_GAME_SUCCESS() {
      this.dispatch('userinfo/req_getBalance')
      this.dispatch('history/req_getHistory', {
        type: 'exchange',
        page: 1,
      });
    },
    DEPOSIT_FROM_BALANCE_PROFIT_STAKE_TO_GAME_SUCCESS() {
      this.dispatch('userinfo/req_getBalance')
      this.dispatch('history/req_getHistory', {
        type: 'exchange',
        page: 1,
      });
    },
    WITHDRAW_FROM_GAME_TO_MAIN_SUCCESS() {
      this.dispatch('userinfo/req_getBalance')
      this.dispatch('history/req_getHistory', {
        type: 'exchange',
        page: 1,
      });
    },
    WITHDRAW_FROM_GAME_STAKE_TO_MAIN_SUCCESS() {
      this.dispatch('userinfo/req_getBalance')
      this.dispatch('history/req_getHistory', {
        type: 'exchange',
        page: 1,
      });
    },
    STATISTIC_WITHDRAW_SBS(state, data) {
      state.statisticWithdrawSBS = data;
    },
    REFRESH_BALANCE_DEMO_SUCCESS() {
      this.dispatch('userinfo/req_getBalance')
      this.dispatch('history/req_getHistory', {
        type: 'exchange',
        page: 1,
      });
    }
  }
};
