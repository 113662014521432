/* eslint-disable no-undef */
const axios = require("../config/axios.config.js").default;
const axiosNode = require("../config/axiosNode.config.js").default;

export default {
    namespaced: true,
    state: () => ({
        userID: "",
        token: "",
    }),

    getters: {},

    actions: {
        req_PostLogin: function ({ commit }, input) {
            axios.post("login", input).then(function (response) {
                if (response) {
                    if (response.require_auth) {
                        commit("REQUIRE_AUTH");
                    } else if(response.level === 1) {
                        commit("LOGIN_SUCCESS", response);
                        // dispatch("userinfo/req_getInfo", null, { root: true });
                        // dispatch("UserInfo/req_getBalance",null,{root:true});
                    } else {
                        commit('LOGIN_FAILED');
                    }
                }
            });
        },
        req_PostRegister: function ({ commit }, input) {
            axios.post("register", input).then(function (response) {
                commit("REGISTER_SUCCESS", response);
            });
        },
        req_PostForgot: function ({ commit }, input) {
            axios.post("forgot-password", input).then(function (response) {
                commit("FORGOT_SUCCESS", response);
            });
        },
        req_SendMail: function ({ commit }, input) {
            axios.post("resend-mail", input).then(function (response) {
                commit("RESEND_SUCCESS", response);
            });
        },
    },

    mutations: {
        LOGIN_SUCCESS: function (state, data) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + data.Bearer;
            axiosNode.defaults.headers.common["Authorization"] = "Bearer " + data.BearerNodeJS;
            window.$cookies.set("user_session", { key: data.Bearer }, "8H");
            window.$cookies.set("socket_session", { key: data.BearerNodeJS }, "8H");
            this.commit('userinfo/SET_USER_INFO', data);
        },
        LOGOUT_SUCCESS: function (state) {
            this.state.userinfo.user = null;
            state.currentAccountId = null;
            window.$cookies.remove("user_session");
            window.$cookies.remove("socket_session");
        },
        REGISTER_SUCCESS: function () { },
        FORGOT_SUCCESS: function () { },
        RESEND_SUCCESS: function () { },
        REQUIRE_AUTH: function () { },
        LOGIN_FAILED() {},
    }
};
