// eslint-disable-next-line no-undef
const axios = require("../config/axios.config.js").default;

export default {
    namespaced: true,
    state: () => ({
        statisticStake: null,
        listStake: null,
        historyStake: null,
    }),

    getters: {
        StatisticStake: (state) => state.statisticStake,
        ListStake: (state) => state.listStake,
        HistoryStake: (state) => state.historyStake,
    },

    actions: {
        req_getStatisticStake({ commit }) {
            axios.get("investment").then((res) => {
                commit("SET_STATISTIC_STAKE_USER", res);
            });
        },
        req_postStake({ commit }, input) {
            axios.post("investment/investment", input).then(() => {
                commit("POST_STAKE_SUCCESS");
            });
        },
        req_getListStake({ commit }, input) {
            axios.get("investment/list", {params: input}).then((res) => {
                commit("SET_LIST_STAKE_USER", res);
            });
        },
        req_postUpgradePackage({ commit }, input) {
            axios.post("investment/upgrade-investment", input).then(() => {
                commit("POST_UPGRADE_PACKAGE_SUCCESS");
            });
        },
        req_getHistoryStake({ commit }, input) {
            axios.get("investment/history", {params: input}).then((res) => {
                commit("SET_HISTORY_STAKE_USER", res);
            });
        },
    },

    mutations: {
        SET_STATISTIC_STAKE_USER: (state, data) => {
            state.statisticStake = data;
        },
        POST_STAKE_SUCCESS() {
            this.dispatch('userinfo/req_getBalance');
            this.dispatch('stake/req_getStatisticStake')
        },
        SET_LIST_STAKE_USER: (state, data) => {
            state.listStake = data.history_invest;
        },
        POST_UPGRADE_PACKAGE_SUCCESS() {
            this.dispatch('stake/req_getStatisticStake');
            this.dispatch('stake/req_getListStake')
        },
        SET_HISTORY_STAKE_USER: (state, data) => {
            state.historyStake = data;
        },
    }
};
