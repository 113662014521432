export default {
  namespaced: true,
  state: () => ({
    typeBalanceSelected: "BS",
    currentTab: "userBet",
    balance: {},
  }),
  getters: {
    CurrentTab: (state) => state.currentTab,
    TypeBalanceSelected: (state) => state.typeBalanceSelected,
    BalanceSocket: (state) => {
      if(state.balance) {
        return state.balance;
      }
      return {};
    },
  },
  actions: {},
  mutations: {
    SET_TYPE_BALANCE_SELECTED(state, data) {
      state.typeBalanceSelected = data;
    },

    SET_CURRENT_TAB(state, data) {
      state.currentTab = data;
    },

    SET_BALANCE(state, data) {
      state.balance = data;
    },
  },
};
