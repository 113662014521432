<template>
  <div class="loaderWrapper">
    <div class="loader" v-if="false">
      <h3 class="text-white">{{ ProgressLoading.percent }}</h3>
    </div>
    <div class="loader">
      <!-- <img :src="require(`@/assets/images/logo-full.png`)" alt="logo-loader" />
      <img
        :src="require(`@/assets/images/mockup/rocket-loader.png`)"
        alt="rocket-loader"
      /> -->
      <p>Loading...{{ ProgressLoading.percent }}%</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LoaderViewPage",
  computed: {
    ...mapGetters({
      ProgressLoading: "ProgressLoading",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: linear-gradient(180deg, #000000, rgba(49, 49, 49, 0.71));
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  // .loader {
  //   border: 6px solid #212121;
  //   border-radius: 50%;
  //   border-top: 6px solid $primaryColor;
  //   width: 60px;
  //   height: 60px;
  //   -webkit-animation: spin 2s linear infinite; /* Safari */
  //   animation: spin 2s linear infinite;

  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   margin-top: -30px;
  //   margin-left: -30px;
  // }
  .loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-bottom: 50px;
    }

    p {
      margin-bottom: 0px;
      font-weight: 500;
      font-size: 20px;
    }
  }
}
</style>
